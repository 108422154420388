.c-node-menu-item[data-v-44e2572c] {
  line-height: 30px;
  padding-left: 10px;
  cursor: pointer;
  color: #444444;
  font-size: 14px;
  border-top: #efefef solid 1px;
}
.c-node-menu-item[data-v-44e2572c]:hover {
  background-color: rgba(66, 187, 66, 0.2);
}